// custom typefaces
import './src/assets/css/_fonts.css'
// normalize CSS across browsers
import './src/normalize.css'
// custom CSS styles
import './src/assets/css/variables/_layout.css'
import './src/assets/css/variables/_typography.css'
import './src/assets/css/variables/_colors.css'
import './src/assets/css/variables/_lang.css'
import './src/assets/css/_wp-block-group.css'
import './src/assets/css/_button-list.css'
import './src/assets/css/_icon-list.css'
import './src/assets/css/_split-list.css'
import './src/assets/css/_mixed-content-list-links.css'
import './src/assets/css/_list-link.css'
import './src/assets/css/_panel-link.css'
import './src/assets/css/_colors.css'
import './src/style.css'

// recoil state management
import React from 'react'
import { RecoilRoot } from 'recoil'

export const wrapPageElement = ({ element, props }) => {
  return <RecoilRoot {...props}>{element}</RecoilRoot>
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}
