// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-antipsychotic-qr-code-links-js": () => import("./../../../src/templates/antipsychotic-qr-code-links.js" /* webpackChunkName: "component---src-templates-antipsychotic-qr-code-links-js" */),
  "component---src-templates-community-prescribers-qr-code-links-js": () => import("./../../../src/templates/community-prescribers-qr-code-links.js" /* webpackChunkName: "component---src-templates-community-prescribers-qr-code-links-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-language-selector-js": () => import("./../../../src/templates/language-selector.js" /* webpackChunkName: "component---src-templates-language-selector-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-section-index-js": () => import("./../../../src/templates/section-index.js" /* webpackChunkName: "component---src-templates-section-index-js" */),
  "component---src-templates-section-js": () => import("./../../../src/templates/section.js" /* webpackChunkName: "component---src-templates-section-js" */)
}

